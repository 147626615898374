import {
    ClosingDocumentCategories,
    ClosingDocuments,
    PreApprovalLetter,
    PreApprovalLetterTemplateType,
    useTenantSetting,
    useTenantSettings,
} from '@nestoca/multi-tenant';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { constSelector, useRecoilValue } from 'recoil';

import {
    getApplicationApplicantAccountMatch,
    getIsChangeApplicationActive,
    getHasSomePropertiesWithBridgeLoan,
    selectedApplication,
    currentApplicationIdState,
    getSubjectProperty,
} from 'store/applications';
import { APPLICATION_TYPE, type ApplicationType } from 'types/application';
import { ClosingDocumentLetterGroupType } from 'types/closing-documents';
import {
    getApplicationMainType,
    getIsInternalRenewal,
} from 'utils/application-type';

import { getSelectedHelocProduct } from './heloc';
import { getHasSubmissionNoteSubmitted } from './submission-notes';

const BLOCKED_STATES = [
    'NOTES_SUBMITTED',
    'LENDER_SUBMITTED',
    'LENDER_APPROVED',
    'PENDING_COMMITMENT_SIGNATURE',
    'PENDING_CONDITIONS',
    'COMPLETE',
    'NOTARY_ALERTED',
    'FUNDED',
    'CLOSED',
    'EXPIRED',
];

export const useUpdateApplicationTypeRights = () => {
    const { applicationType: applicationTypeFlag } = useFlags();

    const isChangeApplicationActive = useRecoilValue(
        getIsChangeApplicationActive
    );

    return applicationTypeFlag && isChangeApplicationActive;
};

export const useCanadaLifeImporterRights = () => {
    const { enableCanadaLifeImporter } = useTenantSettings();

    const { hasCanadaLifeImporterRights: hasCanadaLifeImporterRightsFlag } =
        useFlags();

    return enableCanadaLifeImporter.value && hasCanadaLifeImporterRightsFlag;
};

export const useSyncAccountDetailsRights = () => {
    const { hasSyncAccountDetailsRights } = useFlags();

    const selectedApp = useRecoilValue(selectedApplication);

    const applicationApplicantAccountMatch = useRecoilValue(
        getApplicationApplicantAccountMatch(selectedApp?.id)
    );

    return (
        hasSyncAccountDetailsRights &&
        applicationApplicantAccountMatch &&
        !applicationApplicantAccountMatch.isMatch
    );
};

export const usePreApprovalLetterRights = () => {
    const {
        value: preApprovalLetterEnabled,
        settings: {
            requiresNoteSubmittedState,
            templateOptions = [],
        } = {} as PreApprovalLetter,
    } = useTenantSetting('preApprovalLetter');

    const selectedApp = useRecoilValue(selectedApplication);

    const hasSubmissionNoteSubmitted = useRecoilValue(
        selectedApp
            ? getHasSubmissionNoteSubmitted(selectedApp.id)
            : constSelector(null)
    );

    if (!selectedApp || !preApprovalLetterEnabled) {
        return {
            hasPreApprovalLetterRights: false,
            hasPreApprovalLetterAccess: false,
            tooltipMessage: '',
            letterGroups: [],
        };
    }

    const isInternalRenewal = getIsInternalRenewal(selectedApp);

    if (isInternalRenewal) {
        return {
            hasPreApprovalLetterRights: false,
            hasPreApprovalLetterAccess: false,
            tooltipMessage: '',
            letterGroups: [],
        };
    }

    const PRE_APPROVAL_LETTER_TEMPLATE_MAP: Record<
        PreApprovalLetterTemplateType,
        string
    > = {
        canada_en: 'preApproval.document.canadaEnglish',
        canada_fr: 'preApproval.document.canadaFrench',
        quebec_en: 'preApproval.document.quebecEnglish',
        quebec_fr: 'preApproval.document.quebecFrench',
    };

    const letterGroups: ClosingDocumentLetterGroupType[] = templateOptions.map(
        ({ label, templates }) => ({
            label,
            options: (templates || []).map((template) => ({
                label: PRE_APPROVAL_LETTER_TEMPLATE_MAP[template] || '',
                template,
            })),
        })
    );

    // Check if requires submitted submission notes
    if (requiresNoteSubmittedState) {
        const tooltipMessage = hasSubmissionNoteSubmitted
            ? ''
            : 'preApprovalLetter.requiredToHave.submissionNoteSubmitted';

        return {
            hasPreApprovalLetterRights: true,
            hasPreApprovalLetterAccess: hasSubmissionNoteSubmitted,
            tooltipMessage,
            letterGroups,
        };
    }

    // Return letterGroups when enabled
    return {
        hasPreApprovalLetterRights: true,
        hasPreApprovalLetterAccess: true,
        tooltipMessage: '',
        letterGroups,
    };
};

export const useFinalLetterRights = () => {
    const { finalLetterAccess } = useFlags();

    const selectedApp = useRecoilValue(selectedApplication);

    if (!finalLetterAccess || !selectedApp) {
        return false;
    }

    const isInternalRenewal = getIsInternalRenewal(selectedApp);

    if (isInternalRenewal) {
        return false;
    }

    return true;
};

export const useLoanAgreementLetterRights = () => {
    const { showLoanAgreementDownload } = useFlags();

    const selectedApp = useRecoilValue(selectedApplication);

    if (!showLoanAgreementDownload || !selectedApp) {
        return false;
    }

    const isInternalRenewal = getIsInternalRenewal(selectedApp);

    if (isInternalRenewal) {
        return false;
    }

    const hasLoanProduct = !!selectedApp.product;

    if (!hasLoanProduct) {
        return false;
    }

    const appTypeHasLoanAgreement =
        selectedApp.type !== APPLICATION_TYPE.CHANGE_OF_COVENANT &&
        selectedApp.type !== APPLICATION_TYPE.ASSUMPTION;

    return appTypeHasLoanAgreement;
};

export const useBridgeLoanLetterRights = () => {
    const {
        settings: { enableBridgeLoanLetter },
    } = useTenantSetting('enableBridgeLoan');

    const selectedApp = useRecoilValue(selectedApplication);

    const hasSomePropertiesWithBridgeLoan = useRecoilValue(
        selectedApp?.id
            ? getHasSomePropertiesWithBridgeLoan(selectedApp.id)
            : constSelector(false)
    );

    return hasSomePropertiesWithBridgeLoan && enableBridgeLoanLetter;
};

export const useHelocLoanAgreementLetterRights = () => {
    const hasHelocRights = useHasHelocRights();

    const selectedApp = useRecoilValue(selectedApplication);

    const hasHelocProduct = !!selectedApp?.heloc?.helocAmount;

    return hasHelocRights && hasHelocProduct;
};

export const useUnsignedCommitmentLetterRights = () => {
    const {
        settings: { categories: closingDocumentsCategories },
    } = useTenantSetting('closingDocuments');

    const selectedApp = useRecoilValue(selectedApplication);

    if (!selectedApp || !closingDocumentsCategories) {
        return false;
    }

    const unsignedCommitmentSection = closingDocumentsCategories.find(
        (category) =>
            category.name ===
            ClosingDocumentCategories.UNSIGNED_COMMITMENT_LETTER
    );

    // If this tenant doesn't have an unsigned commitment letter category, exit out
    if (!unsignedCommitmentSection) {
        return false;
    }

    // Get all document keys in the unsigned commitment section
    const unsignedCommitmentDocs = Object.keys(
        unsignedCommitmentSection.documents
    );

    const hasUnsignedComitmentDoc = unsignedCommitmentDocs.includes(
        ClosingDocuments.UNSIGNED_COMMITMENT_LETTER
    );
    const hasComitmentLetterDoc = unsignedCommitmentDocs.includes(
        ClosingDocuments.COMMITMENT_LETTER
    );

    const hasMortgage =
        selectedApp.loanType === 'STANDARD' ||
        selectedApp.loanType === 'ALL_IN_ONE';

    // If the tenant has commitment docs available and there is a mortgage in the application, show this section
    if ((hasUnsignedComitmentDoc || hasComitmentLetterDoc) && hasMortgage) {
        return true;
    }

    const hasHelocComitmentDoc = unsignedCommitmentDocs.includes(
        ClosingDocuments.HELOC_COMMITMENT_LETTER
    );
    const hasHeloc1stAnd2ndDoc = unsignedCommitmentDocs.includes(
        ClosingDocuments.HELOC_1ST_AND_2ND
    );

    const hasHeloc =
        selectedApp.loanType === 'HELOC' ||
        selectedApp.loanType === 'ALL_IN_ONE';

    // If the tenant has heloc docs available and there is a heloc in the application, show this section
    if ((hasHelocComitmentDoc || hasHeloc1stAnd2ndDoc) && hasHeloc) {
        return true;
    }

    return false;
};

export const useNoticeOfCreditChargesRights = () => {
    const {
        value: enableHeloc,
        settings: { noticeOfCreditCharges },
    } = useTenantSetting('enableHeloc');
    const applicationId = useRecoilValue(currentApplicationIdState);
    const subjectProperty = useRecoilValue(getSubjectProperty(applicationId));
    const selectedHelocProduct = useRecoilValue(
        getSelectedHelocProduct(applicationId)
    );

    const noticeOfCreditChargesEnabled = enableHeloc && noticeOfCreditCharges;
    const hasHelocProduct = !!selectedHelocProduct;
    const isQC = subjectProperty?.address?.stateCode === 'QC';

    // Notice of Credit Charges is only available for QC and HELOC products
    return noticeOfCreditChargesEnabled && isQC && hasHelocProduct;
};

export const useSubmissionNotesCreationRights = () => {
    const { hasProcessorRights } = useFlags();

    return !hasProcessorRights;
};

export const usePlaidMonitoringRights = () => {
    const { showPlaidMonitoring, hasUwRights } = useFlags();

    return (showPlaidMonitoring && hasUwRights) as boolean;
};

export const useEditingRights = () => {
    const application = useRecoilValue(selectedApplication);
    const applicationState = application?.applicationState;
    const { hasUwRights, hasProcessorRights, deactivateRights } = useFlags();

    // deactivate right ==== SUPER ADMIN
    if (deactivateRights) {
        return true;
    }

    if (['CLOSED', 'EXPIRED'].includes(applicationState)) {
        return false;
    }

    //we allow a broker to edit at these steps
    if (
        [
            'CREATED',
            'SUBMITTED',
            'UNDER_REVISION',
            'REVIEWED',
            'NOTES_SUBMITTED',
            'LENDER_SUBMITTED',
        ].includes(applicationState)
    ) {
        return true;
    }

    // UW team has edit access to all APPLICATION STATES
    if (hasUwRights) {
        return true;
    }

    //we allow a Processor to edit at this extra step
    if (
        [
            'NOTES_SUBMITTED',
            'LENDER_SUBMITTED',
            'LENDER_APPROVED',
            'PENDING_COMMITMENT_SIGNATURE',
            'PENDING_CONDITIONS',
        ].includes(applicationState) &&
        hasProcessorRights
    ) {
        return true;
    }

    return false;
};

export const useCapitalMarketsRights = (): boolean => {
    const { capitalMarketRights } = useFlags();

    return capitalMarketRights;
};

export const useCmlsUwRights = (): boolean => {
    const { hasCmlsUwRights } = useFlags();

    return hasCmlsUwRights;
};

export const useHasEditRateAdjustmentRights = () => {
    const { capitalMarketRights: hasCapitalMarketsRights, hasCmlsUwRights } =
        useFlags();

    return hasCapitalMarketsRights || hasCmlsUwRights;
};

export const useEditProductAfterNotesSubmittedRights = () => {
    const application = useRecoilValue(selectedApplication);
    const applicationState = application?.applicationState;
    const {
        hasUwRights,
        hasProcessorRights,
        hasLendingRights,
        deactivateRights,
    } = useFlags();

    // deactivate right ==== SUPER ADMIN
    if (deactivateRights) {
        return true;
    }

    // UW team has edit access to all APPLICATION STATES
    if (hasUwRights) {
        return true;
    }

    // IF they have Lending Rights &  Processors Rights and the App is not completed
    if (
        [
            'CREATED',
            'SUBMITTED',
            'UNDER_REVISION',
            'REVIEWED',
            'NOTES_SUBMITTED',
            'LENDER_SUBMITTED',
            'LENDER_APPROVED',
            'PENDING_COMMITMENT_SIGNATURE',
            'PENDING_CONDITIONS',
        ].includes(applicationState) &&
        (hasLendingRights || hasProcessorRights)
    ) {
        return true;
    }

    // If they have no lending right we block them for these states
    if (BLOCKED_STATES.includes(applicationState)) {
        return false;
    }

    return true;
};

export const useDocumentsEditingRights = () => {
    const application = useRecoilValue(selectedApplication);
    const applicationState = application?.applicationState;
    const { hasUwRights, hasProcessorRights, deactivateRights } = useFlags();

    // deactivate right ==== SUPER ADMIN
    if (deactivateRights) {
        return true;
    }

    if (['CLOSED', 'EXPIRED'].includes(applicationState)) {
        return false;
    }

    //we allow a broker to edit at these steps
    if (
        [
            'CREATED',
            'SUBMITTED',
            'UNDER_REVISION',
            'REVIEWED',
            'NOTES_SUBMITTED',
        ].includes(applicationState)
    ) {
        return true;
    }

    // UW team has edit access to all APPLICATION STATES
    if (hasUwRights) {
        return true;
    }

    //we allow a Processor to edit at this extra step
    if (
        [
            'LENDER_SUBMITTED',
            'LENDER_APPROVED',
            'PENDING_COMMITMENT_SIGNATURE',
            'PENDING_CONDITIONS',
        ].includes(applicationState) &&
        hasProcessorRights
    ) {
        return true;
    }

    return false;
};

export const useClosingDocsRights = () => {
    const application = useRecoilValue(selectedApplication);
    const applicationState = application?.applicationState;
    const {
        hasUwRights,
        hasProcessorRights,
        hasLendingRights,
        deactivateRights,
    } = useFlags();

    const {
        settings: { viewEditStates = [], viewOnlyStates = [] },
    } = useTenantSetting('closingDocumentRights');

    // we allow everyone with these flags access to the closing docs without the Application state condition
    // We will have to revert this condition as soon as SF is fixed
    const hasRights =
        hasProcessorRights ||
        hasUwRights ||
        hasLendingRights ||
        deactivateRights;

    if (hasRights) {
        return {
            view: true,
            edit: true,
        };
    }

    if (viewEditStates.includes(applicationState)) {
        return {
            view: true,
            edit: true,
        };
    }

    if (viewOnlyStates.includes(applicationState)) {
        return {
            view: true,
            edit: false,
        };
    }

    return {
        view: false,
        edit: false,
    };
};

export const useHasHelocRights = () => {
    const {
        value: enableHeloc,
        settings: { supportsRenewalTransactions },
    } = useTenantSetting('enableHeloc');

    const application = useRecoilValue(selectedApplication);

    if (!application) {
        return false;
    }

    const { isRenewalApplication } = getApplicationMainType(application.type);

    if (!isRenewalApplication) {
        return enableHeloc;
    }

    // Don't allow HELOC for internal referral renewals
    // https://nestoca.atlassian.net/browse/OG-11093
    const hasLoanNumberAtReferral = !!application?.loanNumberAtReferral;

    return (
        enableHeloc && supportsRenewalTransactions && !hasLoanNumberAtReferral
    );
};

export const useShowDigitalFlag = (isDigital?: boolean) => {
    const { value: enableDigitalFlag } = useTenantSetting('enableDigitalFlag');
    return enableDigitalFlag && !!isDigital;
};

export const useHasEnableMaturityDateOverride = () => {
    const { enableMaturityDateOverride } = useTenantSettings();
    const application = useRecoilValue(selectedApplication);

    const isNestoPort = application.type === 'NEW' && application.isPort;

    const supportedIGTransactionTypes = (
        [
            APPLICATION_TYPE.ASSUMPTION,
            APPLICATION_TYPE.CHANGE_OF_COVENANT,
            APPLICATION_TYPE.PORT,
        ] as Partial<ApplicationType>[]
    ).includes(application?.type);

    const supportedTransactionTypes =
        isNestoPort || supportedIGTransactionTypes;

    return enableMaturityDateOverride.value && supportedTransactionTypes;
};

// hasProcessorRights has edit rights for the following STATES:
// APPLICATION REVIEW -
//  'CREATED'
// | 'SUBMITTED'
// | 'UNDER_REVISION'
// | 'REVIEWED'
// | 'NOTES_SUBMITTED'
// | 'LENDER_SUBMITTED'

// DOCUMENTS SECTION:

// 'CREATED'
// | 'SUBMITTED'
// | 'UNDER_REVISION'
// | 'REVIEWED'
// | 'NOTES_SUBMITTED'
// | 'LENDER_SUBMITTED',
// | 'LENDER_APPROVED',
// | 'PENDING_COMMITMENT_SIGNATURE',
// | 'PENDING_CONDITIONS'

// hasUwRights has edit rights for the following STATES:
// APPLICATION REVIEW - & DOCUMENTS SECTION
//  'CREATED'
// | 'SUBMITTED'
// | 'UNDER_REVISION'
// | 'REVIEWED'
// | 'NOTES_SUBMITTED'
// | 'LENDER_SUBMITTED'
// | 'LENDER_SUBMITTED',
// | 'LENDER_APPROVED',
// | 'PENDING_COMMITMENT_SIGNATURE',
// | 'PENDING_CONDITIONS',
